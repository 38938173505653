<template>
  <div
    class="esc-button electron-no-drag"
    :class="{ 'button-with-label': buttonLabel }"
  >
    <div
      class="square-icon-wrap group m-0"
      :class="{ 'h-w-32': !isMobile }"
      @click.stop="handleMouseDown"
    >
      <v-popover
        v-if="!isMobile"
        :trigger="'hover'"
        :popover-class="popoverClass"
        :disabled="draggingIsOn"
      >
        <div class="centerIcon">
          <em
            :style="iconStyle"
            class="custom-icons bg-icon-default group-hover:bg-icon-hover"
          />
          <span
            v-if="buttonLabel"
            class="text-text-default button-label"
            >{{ buttonLabel }}</span
          >
        </div>
        <template slot="popover">
          <TooltipTemplate
            :tooltip-text="translate(label)"
            :tooltip-meta="tooltipMeta"
          />
        </template>
      </v-popover>
      <div
        v-else
        class="centerIcon"
      >
        <em
          :style="mobileIconStyle"
          class="custom-icons bg-icon-default"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TooltipTemplate from '@/components/elements/TooltipTemplate';
import useCustomIcon from '@/utilities/composables/useCustomIcon';

export default {
  name: 'EscTooltip',
  components: {
    TooltipTemplate,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'x',
    },
    popoverClass: {
      type: String,
      required: false,
      default: 'black-tooltip mt-19',
    },
    label: {
      type: String,
      required: false,
      default: 'close',
    },
    tooltipMeta: {
      type: Object,
      required: false,
      default: () => ({ icon: { ctrl: false, key: 'ESC' } }),
    },

    buttonLabel: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const { getCustomIconStyle, iconPath } = useCustomIcon();

    return {
      getCustomIconStyle,
      iconPath,
    };
  },
  computed: {
    iconStyle() {
      return {
        ...this.getCustomIconStyle(this.iconPath(this.icon)),
      };
    },
    mobileIconStyle() {
      if (!this.isMobile) return '';
      return {
        ...this.getCustomIconStyle(this.iconPath('chevron-left')),
      };
    },
  },
  methods: {
    handleMouseDown(event) {
      this.$emit('closePopup', event);
    },
  },
};
</script>

<style lang="scss">
.esc-button {
  .centerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;

    em {
      @apply flex items-center w-5 h-5;
    }
  }
  .v-popover {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.button-with-label {
    width: auto !important;

    .square-icon-wrap {
      width: auto !important;
      padding: 0 8px 0 4px;

      .centerIcon {
        width: auto !important;

        .button-label {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

.mobile {
  .esc-button {
    .square-icon-wrap {
      @apply h-5
      w-5;
    }
  }
}
</style>
