<template>
  <div
    class="st-checkbox pt"
    :class="{ disabled: disabled }"
    @click="toogleValue"
  >
    <div
      v-tooltip="tooltip"
      class="checklist-item-icon pt"
      :class="[{ checked: checked }]"
    >
      <em
        class="custom-icons"
        :style="getCustomIconStyle(iconPath('check'))"
      />
    </div>
    <span
      class="checklist-item-text"
      :class="[{ checked: checked }]"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import useCustomIcon from '@/utilities/composables/useCustomIcon';

export default {
  name: 'StCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup() {
    const { iconPath, getCustomIconStyle } = useCustomIcon();

    return {
      iconPath,
      getCustomIconStyle,
    };
  },
  data() {
    return {
      checked: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.checked = newVal;
    },
  },
  methods: {
    toogleValue() {
      if (this.disabled) return;

      this.checked = !this.checked;
      this.$emit('changeValue', this.checked);
    },
  },
};
</script>

<style lang="scss">
.st-checkbox {
  display: flex;
  align-items: center;
  margin-right: 33px;

  .checklist-item-icon {
    height: 18px;
    width: 18px;
    padding: 1px;
    transition: 0.3s ease-in-out;
    border: 1px solid $gray300;
    transition-property: background-color, border;
    @apply flex items-center justify-center rounded-md mr-3 bg-transparent;

    .custom-icons {
      transition: 0.3s ease-in-out;
      transition-property: background-color, opacity;
      @apply bg-gray-300 opacity-0;
    }

    &.checked {
      border: 1px solid $stBlue;

      .custom-icons {
        @apply bg-st-stBlue opacity-100;
      }
    }
  }

  &.disabled {
    .checklist-item-icon {
      @apply bg-inactive-bg border-inactive-border;

      &.checked {
        .custom-icons {
          @apply bg-inactive-icon;
        }
      }
    }
  }

  .checklist-item-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    @apply text-text-default;
  }
}
</style>
