var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-member-content",class:{ 'popup-form-padding': !_vm.isLoginPage }},[(_vm.isOnboardingInfo)?_c('p',{staticClass:"onboarding_form_description"},[_vm._v(" "+_vm._s(_vm.translate('STIsMostUseful'))+" ")]):_vm._e(),(_vm.isOnboardingInfo)?_c('p',{staticClass:"mb-[6px] font-medium text-text-default leading-6"},[_vm._v(" "+_vm._s(_vm.translate('emailAdresses'))+" ")]):_vm._e(),_c('div',{ref:"memberSearchInput",staticClass:"member-search-input"},[_c('div',{staticClass:"autoexpand-email-area"},[_c('vue-scroll',{staticClass:"autoexpand-email-area-input",class:{
          'not-valid': _vm.validateEmails,
        },attrs:{"ops":{
          scrollPanel: { scrollingX: false },
          rail: { gutterOfSide: '4px', gutterOfEnds: '8px' },
          bar: { size: '4px', keepShow: true },
        }}},[_c('AutoExpandTextarea',{ref:"quickCardTitleInput",attrs:{"text-value":_vm.textArea,"focus-field":true,"textarea-class":'',"initial-height":60,"placeholder":_vm.translate('person1Email'),"disabled":!_vm.isElevated},on:{"esc":_vm.closePopup},model:{value:(_vm.textArea),callback:function ($$v) {_vm.textArea=$$v},expression:"textArea"}})],1)],1),_c('span',{staticClass:"section-sublabel w-full opacity-0 mt-2",class:{ 'opacity-100': !_vm.isOnboardingInfo && _vm.existingMembersAdded }},[_vm._v(_vm._s(_vm.translate('alreadyMembersOfWorkspace')))]),_c('span',{staticClass:"section-label usn"},[_vm._v(_vm._s(_vm.translate('inviteAs')))]),_c('ListDropdownWrap',{ref:"roleDropdown",staticClass:"w-full mb-2 border-important-border",attrs:{"dropdown-width":_vm.dropdownWidth,"height":44,"items":_vm.accountTypes,"selected-item":_vm.selectedBoardIndex,"disable-scroll":true,"disabled":!_vm.isElevated},on:{"item-click":_vm.handleRoleClick}}),_c('div',{staticClass:"lower-members-invite-form-part"},[_c('span',{staticClass:"section-sublabel"},[_vm._v(_vm._s(_vm.accountTypesExplanation))]),_c('span',{staticClass:"section-label usn"},[_vm._v(_vm._s(_vm.translate('addToSpaces')))]),(_vm.guestRole)?_c('span',{staticClass:"section-sublabel mb-1",class:{
          'text-red-600': _vm.invitedGuestWithoutProject,
        }},[_vm._v(" "+_vm._s(_vm.translate('guestsMustBeAddedToOneSpace'))+" ")]):_vm._e(),_c('div',{staticClass:"w-full flex items-center gap-2 max-w-[552px] h-full flex-wrap"},[_vm._l((_vm.selectedProjects),function(project,index){return _c('div',{key:index},[_c('div',{staticClass:"added-projects"},[_c('ProjectIcon',{staticClass:"h-4 w-4 mr-1",attrs:{"project-icon":project.icon || '',"project-color":project.customIconColor || '',"type":project.iconType || ''}}),_c('span',{staticClass:"project-title text-text-default whitespace-nowrap overflow-hidden text-ellipsis usn"},[_vm._v(" "+_vm._s(_vm.projectPillTitle(index))+" ")]),(project.is_private)?_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.translate('thisSpaceIsPrivate'),
                trigger: 'hover',
                classes: 'black-tooltip sd-pr-icn private-space-icon mt-19',
                container: false,
                placement: 'bottom',
                boundariesElement: 'window',
              }),expression:"{\n                content: translate('thisSpaceIsPrivate'),\n                trigger: 'hover',\n                classes: 'black-tooltip sd-pr-icn private-space-icon mt-19',\n                container: false,\n                placement: 'bottom',\n                boundariesElement: 'window',\n              }"}],staticClass:"custom-icons private-icon",style:([_vm.getCustomIconStyle(_vm.iconPath('private-project'))])}):_vm._e(),_c('em',{staticClass:"custom-icons shrink-0 ml-1 cursor-pointer",style:(_vm.getCustomIconStyle(_vm.iconPath('x'))),on:{"click":function($event){return _vm.removeProject(index)}}})],1)])}),_c('ButtonCustom',{ref:"filterButton",staticClass:"text-text-default hover:bg-hover-default gap-1",class:[{ 'button-with-text': !_vm.isAddButtonEmpty }, { 'pl-2': _vm.isAddButtonEmpty }],attrs:{"text":_vm.addButtonText,"type":'important-custom',"variation":'outlined',"button-dimensions":{
            width: _vm.isAddButtonEmpty ? _vm.buttonCustomWidth : 'max-content',
            height: 24,
          },"label-left":true,"no-label":_vm.isAddButtonEmpty,"custom-icon-name":'plus2',"custom-icon-size":'12px',"theme-style":true,"disabled":!_vm.isElevated},on:{"click":_vm.projectDropdownClickHandler}}),_c('v-popover',{attrs:{"open":_vm.showProjectDropdown,"trigger":'manual',"popover-class":'popover-dropdown pt-2 pb-4 pl-5'}},[_c('template',{slot:"popover"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"projects-dropdown-wrap"},[_c('div',{staticClass:"projects-header-wrap projects-search usn"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],ref:"searchInput",staticClass:"projects-header",attrs:{"placeholder":`${_vm.translate('addToSpaces')}...`,"type":"text"},domProps:{"value":(_vm.searchValue)},on:{"keyup":_vm.checkKey,"compositionupdate":_vm.compositionUpdate,"keydown":_vm.checkDownKey,"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}})]),_c('vue-scroll',{ref:"projectsScroll",staticClass:"projects-list",class:{ scrolling: _vm.scrolling },attrs:{"ops":{
                  scrollPanel: { scrollingX: false },
                  bar: { keepShow: true, size: '4px' },
                  rail: { gutterOfSide: '2px' },
                }}},[(_vm.projectsNotFound)?_c('div',{staticClass:"projects-notfound-wrap"},[_c('p',{staticClass:"text-text-default"},[_vm._v(_vm._s(_vm.translate('noProjectFound')))])]):_vm._e(),_vm._l((_vm.filteredProjects),function(project,index){return _c('div',{key:index,on:{"mousedown":function($event){$event.preventDefault();}}},[_c('FilterDropdownItem',{attrs:{"index":index,"item":project,"items":_vm.filteredProjects,"selected-item-index":_vm.selectedIndex},on:{"item-hover":function($event){return _vm.itemHover(index)},"scroll-to-item":_vm.scrollToItem,"item-click":function($event){return _vm.handleProjectClick(project, index)},"item-right-side-click":function($event){return _vm.itemRightSideClick(project, index)}}})],1)})],2)],1)])],2)],2)]),(!_vm.isElevated)?_c('section',{staticClass:"w-full"},[_c('Box',{staticClass:"relative w-full flex flex-row content-center border-blue-500 bg-highlight-bg mt-4 py-2 px-4"},[_c('em',{staticClass:"h-4 w-4 custom-icons page-toolbar self-center dark:bg-blue-300 bg-blue-600 ml-0 mr-2",style:(_vm.getCustomIconStyle(_vm.iconPath('info')))}),_c('Header',{attrs:{"title":{
            value: _vm.translate('invitingPeopleToWorkspace'),
            class: 'text-sm font-normal pt-1.5',
          }}})],1)],1):_vm._e()],1),(!_vm.freePlan)?_c('section',[(_vm.canceledRenewal)?_c('Box',{staticClass:"relative w-full flex flex-row content-center border-red-500 bg-destructive-bg mt-4 py-2 px-4"},[_c('em',{staticClass:"h-4 w-4 custom-icons page-toolbar self-center dark:bg-red-300 bg-red-600 ml-0 mr-2",style:(_vm.getCustomIconStyle(_vm.iconPath('info')))}),_c('Header',{attrs:{"title":{
          value: _vm.translate('billing.yourCurrentPlanIsNotSetToRenew'),
          class: 'text-sm font-normal',
        }},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h2',{staticClass:"text-xs leading-[1.125rem] font-normal"},[_vm._v(" "+_vm._s(_vm.translate('billing.youCanStillInvite'))+" "),_c('b',[_vm._v(_vm._s(_vm.billingDetails.subscription.seat_count))]),_vm._v(" "+_vm._s(_vm.translate('billing.workspaceMembers'))+". ")])]},proxy:true}],null,false,1361974525)})],1):_c('Box',{staticClass:"relative w-full flex flex-row content-center border-blue-500 bg-highlight-bg mt-4 py-2 px-4"},[_c('em',{staticClass:"h-4 w-4 custom-icons page-toolbar self-center dark:bg-blue-300 bg-blue-600 ml-0 mr-2 flex-shrink-0",style:(_vm.getCustomIconStyle(_vm.iconPath('info')))}),_c('Header',{attrs:{"title":{
          value: _vm.translate('billing.weBillTheWorkspacePayment'),
          class: 'text-sm font-normal',
        },"subtitle":{
          value: _vm.translate('billing.checkThePlanPanel'),
          class: 'text-xs leading-[1.125rem] font-normal',
        }}})],1)],1):_vm._e(),_c('div',{staticClass:"flex mt-[24px] pb-6 flex-row justify-end footer-buttons-members-invite"},[_c('ButtonCustom',{ref:"cancelButton",attrs:{"text":_vm.translate('cancel'),"type":'popup-button-wrap important',"button-dimensions":{
        width: 100,
        height: 36,
      }},on:{"click":_vm.handleClose}}),_c('div',{staticClass:"empty-separator"}),_c('ButtonCustom',{ref:"sendButton",attrs:{"text":_vm.translate('send'),"type":'popup-button-wrap attention',"disabled":_vm.validateEmails || _vm.emptyInput,"button-dimensions":{
        width: 100,
        height: 36,
      }},on:{"click":_vm.inviteUser}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }