<template>
  <div
    class="toggle-section-wrap usn"
    :class="{ highlighted }"
  >
    <span
      v-if="label"
      class="toggle-section-label"
    >
      {{ label }}
    </span>
    <div class="toggle-section-items">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        class="toggle-section-item pt"
        @click="$emit('click', item, index)"
      >
        <div
          class="toggle-section-item-content"
          :class="{ selected: item.selected === item.id }"
        >
          <em
            v-if="item.customIconName"
            :style="[getCustomIconStyle(item.customIconName), { background: item.customIconColor }]"
            class="custom-icons toggle-section-item-icon"
          />
          <span
            v-else
            class="custom-text"
            :style="item.customTextStyle"
            >{{ item.customText || 'Aa' }}</span
          >
        </div>
        <span
          v-if="item.label"
          class="toggle-section-item-label"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'ToggleSection',
  mixins: [customIconsMixin],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.toggle-section-wrap {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  row-gap: 3px;

  &.highlighted {
    @apply bg-important-hover;
  }

  .toggle-section-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    @apply text-text-default;
  }

  .toggle-section-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .toggle-section-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 2px;

      &:hover {
        .toggle-section-item-content:not(.selected) {
          border: 1px solid $primary200;

          .custom-text {
            color: $gray500;
          }
        }
      }

      .toggle-section-item-content {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $gray200;
        width: 64px;
        height: 64px;
        box-sizing: border-box;
        border-radius: 8px;

        .custom-text {
          font-style: normal;
          font-size: 20px;
          line-height: 30px;
          color: $gray400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px 1px;
        }

        &.selected {
          border: 1px solid $primary200;
          @apply bg-important-hover;

          .custom-text {
            @apply text-text-default;
          }
        }
      }

      .toggle-section-item-label {
        font-size: 12px;
        line-height: 18px;
        @apply text-text-default;
      }
    }
  }
}
</style>
